import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
// import { FaRegWindowClose } from 'react-icons/fa'
// import { Link } from 'react-router-dom'

//prettier-ignore
export const Contact = () => {
  const form = useRef()
  const [resText, setResText] = useState('')
  const [resError, setResError] = useState('')
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ message, setMessage ] = useState('')
  // const [ wasSent, setWasSent ] = useState(null)

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_tnulx5l',
        'template_giqqwz7',
        form.current,
        'CR6MBiPu1IJ5ObH5I'
      )
      .then(
        (result) => {
          setResText(result.text)
          // setWasSent(true)
          console.log(result.text)
        },
        (error) => {
          setResError(error.text)
          // setWasSent(true)
          console.log(error.text)
        }
      )
  }

  // const handleClose = (e) => {
  //   console.log('handleClose');
  // }
  

  return (
    <div 
      className='page page-contact bg-noise'
      >
      <div className='container'>
        <form 
          className='contact-form'
          ref={form} 
          onSubmit={sendEmail}
          >
          {/* <div className="close-box"><Link to="/"><FaRegWindowClose /></Link></div> */}
          <div className="contact-form--title">
            <div className='horiz-rule-above'></div>
            <h2>CONTACT CROSS &amp; HIGH</h2>
            <div className="horiz-rule-below"></div>
          </div>
          <div className="contact-form--feedback">
            {!resText && <p className='contact-form-text'>Thanks for visiting!&nbsp;&nbsp;We'd love to hear from you. Send us a message here or on <a href="https://www.facebook.com/groups/924264675021222" alt="Cross &amp; High Facebook Group">our Facebook page</a>.</p>}
            {resText && <p className='contact-form-text'>Thanks for your message. You'll hear from us soon.</p>}
            {resError && <p className='contact-form-text'>Your message wasn't sent. Maybe try again later?</p>}
          </div>
          <div className="contact-form--input">
            <div className='contact-form--input-name'>
              <label>Your name:</label>
              <input 
                type='text' 
                name='user_name'
                onChange={ (e) => setName(e.target.value)}
                value={ name }
                />
            </div>
            <div className="contact-form--input-email">
              <label>Your email:</label>
                <input 
                  type='email' 
                  name='user_email'
                  onChange={ (e) => setEmail(e.target.value)}
                  value={ email }
                  />
            </div>
            <div className="contact-form--input-message">
              <label>Message:</label>
              <textarea 
              name="message"
              onChange={ (e) => setMessage(e.target.value)}
              value={ message }
              />
            </div>
            <button className='contact-form-send' type='submit'>SEND</button>
          </div>
        </form>
      </div>
    </div>
  )
}
