import { useState } from 'react'
// import PlusIcon from '../assets/icon-plus.png'
// import { FaEllipsisV, FiX } from 'react-icons/fi'
// import { FaHome } from 'react-icons/fa'
import { Link } from 'react-router-dom'
// import './NavBar.scss'

//prettier-ignore
export default function Navbar() {

  const [isNavExpanded, setIsNavExpanded] = useState(false)


  return (
    <>
      <nav className="mainnav">
        <div className="mainnav-mobile">
          <button 
            className="mainnav-icon"
            onClick={ () => setIsNavExpanded(!isNavExpanded)}
            >
            {/* icon from heroicons.com */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="#000"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          className={isNavExpanded ? "mainnav-menu expanded" : "mainnav-menu"}>
          <ul>
            {/* <li>
              <Link to="/" className='' onClick={ () => setIsNavExpanded(false)}><FaHome /></Link>
            </li> */}
            <li>
              <Link to="/" className='' onClick={ () => setIsNavExpanded(false)}>Home</Link>
            </li>
            <li>
              <Link to="/about" onClick={ () => setIsNavExpanded(false)}>About</Link>
            </li>
            {/* <li>
              <Link to="/songs" onClick={ () => setIsNavExpanded(false)}>Songs</Link>
            </li> */}
            {/* <li>
              <Link to="/bios" onClick={ () => setIsNavExpanded(false)}>Bios</Link>
            </li> */}
            <li>
              <Link to="/contact" onClick={ () => setIsNavExpanded(false)}>Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}
