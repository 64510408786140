//prettier-ignore
import { FaAngleDoubleUp } from 'react-icons/fa'

//prettier-ignore
const BackToTopButton = ({scrollToTopCallback}) => {
  return (
    <div className='back-to-top'>
      <button className='back-to-top-button'
        onClick={() => scrollToTopCallback()
      }
      >
        Back To Top&nbsp;
        <FaAngleDoubleUp />
      </button>
    </div>
  )
}

export default BackToTopButton
