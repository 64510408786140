//prettier-ignore
export default function ReadMoreButton({ scrollIntoViewCallback }) {
  return (
    <div className='read-more'> 
      <button 
        className='btn read-more-button arrow-down' 
        onClick={() => scrollIntoViewCallback()
        }
        >
        Read More &raquo;
        {/* Read More */}
      </button>
    </div>
  )
}
