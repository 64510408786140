import VanAndSteve from '../assets/VanAndSteve.jpg'

//prettier-ignore
export default function BandPic() {
  return (
    <div className='bandpic grid-item-5'>
      <div
        className="bandpic-img" 
        style={{ backgroundImage: `url(${VanAndSteve})` }}>
      </div>
    </div>
  ) 
}
