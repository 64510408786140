import NavBar from './NavBar'
import IconBar from './IconBar'

//prettier-ignore
export const Header = () => {


  return (
    <>
      <header className='header'>
        <div className="header-content bg-noise">
          <div className="header-content-items">
            <NavBar />
            <IconBar />
          </div>
        </div>
      </header>
    </>
  )
}
