// import { useState, useEffect } from 'react'
import { Slideshow, SlideshowItem } from './Slideshow'
import './Slideshow.css'

//prettier-ignore
export default function SlideshowComponent( { imagedata } ) {

  const { images } = imagedata


  return (
    <>
      <Slideshow width={100} height={100}>
        {
          images.map((image, i) => {
            return (
              <SlideshowItem key={image.url} >
                <SlideshowImage alt={image.caption} img={image.url}/>
              </SlideshowItem>
            )
          })
        }
      </Slideshow>
    </>
  )
}

// !VA Destructure out the img property source from the img props object
//prettier-ignore
function SlideshowImage( {img, alt} ) {
  // !VA Initialize the  the image POS to the image prop
  // const [image, setImage] = useState(null)

  // useEffect(() => {

  //   setImage(img)
  // }, []);

  // !VA Use useAsync from ax-react-lib to get images from an API - or better yet, use axios because ax-react-lib doesn't support React 18
  // useAsync(async () => {
  //   const data = await fetch(
  //     `https://jsonplaceholder.typicode.com/photos/${Math.round(
  //       Math.random() * 500
  //     )}`
  //   ).then((response) => response.json())
  //   setData(data)
  // })

  return (
        <img src={img} alt={alt}></img>
    )
}
