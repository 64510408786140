import { useRef } from 'react'
import BrotherRobert from '../assets/brother-robert.png'
import BandPic from '../components/BandPic'
import StreetSignPic from '../components/StreetSignPic'
import SeparatorDots from '../components/SeparatorDots'
import PageBorder from '../components/PageBorder'
// import SeparatorRule from '../components/SeparatorRule'
import UrlBar from '../components/UrlBar'
// import ReadMore from '../components/ReadMore'
import ReadMoreButton from '../components/ReadMoreButton'
import BackToTopButton from '../components/BackToTopButton'

//prettier-ignore
const HomeContent2 = ({ scrollIntoViewProps, scrollToTopCallback }) => {
  return (
    <>
      {/* The read-more--content section needs to be a sibling of the 'intro' container to avoid inheriting its layout and position. The 'more' content needs to be a separate, independent container with its own layout. It should also be the direct previous sibling of the element with the read-more class   */}
      <div
        className='page page-home-2'
        ref={scrollIntoViewProps}
        >
          <PageBorder />
          <div className="container">
            <article className='home-crossroads'>
              <section>
                  <BackToTopButton scrollToTopCallback={scrollToTopCallback}/>
                    <div className="subtitle">
                      <h3 className='rule-above rule-below'>Mr Johnson's Crossroads</h3>
                    </div>
                    <figure className='figure-desktop'>
                      <img id='read-more--image' src={BrotherRobert} alt="Robert Johnson"/>
                    </figure>
                    <p className='text-content'>
                    Everyone knows the legend of how Robert Johnson met the devil at the Crossroads and sold his soul to play guitar and sing the blues.  </p>
                    <figure className='figure-mobile'>
                      <img id='read-more--image' src={BrotherRobert} alt="Robert Johnson" />
                    </figure>
                    <p className='text-content'>
                    Robert Johnson was talented and charismatic, which no doubt made him the subject of a lot of evil talk. In 2020, a new photo surfaced on the cover of <a href="https://www.npr.org/2020/12/29/950794131/brother-robert-reveals-true-story-of-growing-up-with-blues-legend-robert-johnson" alt="Brother Robert by Annye Anderson" target="_blank" rel="noreferrer noopener">his stepsister's book</a> that reveals a personable, not-at-all-diabolical Robert Johnson and hopefully puts the fictional Crossroads story to rest. 
                    </p>
                    
                    <p className='text-content'>The location of Mr Johnson's real-life Crossroads was the Yazoo River Basin of Mississippi. In fact, nearly all the originators of what we now know as <span className='text-light-emphasis'>blues</span> hailed from that same small patch of land. Something was happening there that wasn’t happening anywhere else on&nbsp;Earth, so it's a place worth celebrating.
                  </p>
                  <p className='text-content space-below'>
                    The Crossroads also symbolizes the intersection of <span className='semibold'>Cross &amp; High's</span> Ellis Island-era immigrant heritage with the African-American experience. We started playing this music as kids. It's always been a big part of our lives, and we want to pay homage to it and to the people who created it. This music is a gift that the people of the Yazoo Basin gave the world a century ago. We’re grateful for it, and we hope that comes across to you when we play. 
                  </p>
                  {/* <p className='text-content space-below'>
                    You can learn more about <span className='semibold'>Cross &amp; High</span> in the weeks ahead as we build out our website and performing schedule. So check back in once in a while and join <a href='https://www.facebook.com'>our Facebook group</a> for info about upcoming gigs and events. 
                  </p> */}
              </section>
          </article>
        </div>
      </div>
    </>
  )
}

//prettier-ignore
const HomeContent1 = ({ scrollIntoViewCallback }) => {
  return (
    <>
      <div 
        className='page page-home-1 bg-noise'
        >
        <div className='container'>
          <article className='grid-container home-landing'>
            {/* Title */}
            <div className='title grid-item-1'>
              <div className='title-text'>
                  <h1 className='rule-above rule-below'>Cross &amp; High</h1>
              </div>
              <UrlBar />
            </div>
            {/* Hero */}
            <StreetSignPic />
            <div className='subtitle grid-item-3'>
              <div className='subtitle-text'>
                <SeparatorDots />
                <h2 className=''>Yazoo River Basin Blues</h2>
                <SeparatorDots />
              </div>
              {/* <div className='horiz-rule-below'></div> */}
            </div>
            {/* Description */}
            <section className='grid-item-4'>
              <p className='text-content hide'>
                About 100 years ago, a Mississippi bluesman went down to the crossroads
                and made a legendary deal that changed the course of music all over
                the world.
              </p>
              <p className='text-content hide'>
                In our neck of the woods, the crossroads is at the corner
                of&nbsp;
                <span className='semibold'>
                  Cross&nbsp;&amp;&nbsp;High.
                </span>
              </p>
            </section>
            {/* Band Pic */}
            <BandPic />
          </article>{/* article */}
        </div>{/* container */}
        {/* Read More Button - don't forget that this uses the scroll-margin property on all pages to compensate for the height of the header */}
        <ReadMoreButton scrollIntoViewCallback={scrollIntoViewCallback} />
      </div>{/* page */}
    </>
  )
}

//prettier-ignore
export const Home = () => {

  const homeContent2Ref = useRef()

  const scrollIntoViewCallback = () => {
    console.log('scrollIntoViewCallback running...')
    homeContent2Ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    console.log('HERE');

    
  }

  const scrollToTopCallback = () => {
    console.log('scrollIntoViewCallback running...')
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <>
    <div className='page-wrap'>
          <HomeContent1 scrollIntoViewCallback={scrollIntoViewCallback} />  
          <HomeContent2 scrollIntoViewProps={homeContent2Ref} scrollToTopCallback={scrollToTopCallback} />
    </div>

    </>
  )
}
