//prettier-ignore

export default function UrlBar() {
  return (
    <div className="urlbar">
      <div className="urlbar__url-text">
        <p className='rule-above rule-below content__url-text'><a className='urlbar-link' href="https://www.crossandhigh.com">https://www.crossandhigh.com</a></p>
      </div>
    </div>
  )
}
