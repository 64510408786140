import CrossroadsPic from '../assets/Crossroads.png'
// import UrlBar from './UrlBar'

//prettier-ignore
export default function StreetSign() {
  return (
    <>
      <div className='hero grid-item-2'>
        <div className="hero-img" 
          style={{ backgroundImage: `url(${CrossroadsPic})` }}>
          {/* CSS Vignette on background image */}
          <div className='vignette'></div>
        </div>
      </div>
    </>
  )
}
