import { FaFacebookF } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'

//prettier-ignore
export default function IconBar() {
  return (
    <div className="icon-menu">
      <a href="https://www.facebook.com/groups/924264675021222" target="_blank" rel="noreferrer noopener"><FaFacebookF className='fb-icon nav-icon'/></a>
      <a href="https://www.youtube.com/channel/UCuP_6ffWH0Lx56fyZxb_Dmw" target="_blank" rel="noreferrer noopener"><FaYoutube className='fb-icon nav-icon'/></a>
      <a href="https://www.instagram.com/crossandhigh/" target="_blank" rel="noreferrer noopener"><FaInstagram className='fb-icon nav-icon'/></a>
    </div>
  )
}
