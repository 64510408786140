/* !VA This component runs every time the route changes and scrolls to the top of the page. Otherwise, React will display the page at the position the page was prior to the route change.  */
// ScrollToTop.jsx
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = (props) => {
  // console.log('props :>> ')
  // console.log(props)
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <>{props.children}</>
}

export default ScrollToTop
