import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'

import './App.scss'
import { Header } from './components/Header'
import { Home } from './pages/Home'
import { About } from './pages/About'
// import { Songs } from './pages/Songs'
// import { Bios } from './pages/Bios'
import { Contact } from './pages/Contact'

// import { Navbar } from './components/Navbar'

//prettier-ignore
function App() {
  return (
    <div className='App'>
      <BrowserRouter>
      <div className="site-wrapper">
        <Header />
        <main>
          <ScrollToTop>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} /> 
              {/* <Route path='/songs' element={<Songs />} />
              <Route path='/bios' element={<Bios />} /> */}
              <Route path='/contact' element={<Contact />} />
            </Routes>
          </ScrollToTop>
        </main>
      </div>
      </BrowserRouter>
    </div>
  )
}

export default App
