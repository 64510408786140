import SeparatorDots from '../components/SeparatorDots'
import { FaRegMinusSquare, FaRegPlusSquare } from 'react-icons/fa'
import PiedmontBlues from '../assets/PiedmontBluesAlbums.jpg'
import TexasBlues from '../assets/TexasBluesAlbums.png'
import CountryBlues from '../assets/CountryBluesAlbums.jpg'
import PageBorder from '../components/PageBorder'
import chicagoalbumimagedata from '../data/ChicagoAlbumImages.json'
import deltabluesalbumimagedata from '../data/DeltaBluesAlbumImages.json'
import SlideshowComponent from '../components/slideshow/SlideshowComponent'
import { useState, useRef } from 'react'

// !VA Receive the props from the AccordionContent tags.
//prettier-ignore
const AccordionContent = ({
  title,
  children,
  index,
  activeIndex,
  setActiveIndex,
}) => {
  // !VA 
  const accordionRef = useRef()

  const handleSetIndex= (index) => {
    if (activeIndex !== index ) {
      setActiveIndex(index);
      // console.log('LATER activeIndex :>> ' + activeIndex);
    } else {
      setActiveIndex(0);
    }
    // !VA Once the accordion content has expanded, we can move the top of the accordion container to the top of the screen with the scroll-margin set in the CSS. If you scrollIntoView before the accordion is expanded, the scroll operation will run first and then when the accordion opens it will scroll again to accommodate the new container.
    setTimeout(() => {
      accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 300);
  }

  return (
    <>
      <div 
        className={`accordion-container`}
        ref={accordionRef}
        >
        <div
          onClick={() => handleSetIndex(index)}
          className='accordion'>
          <div 
          className='accordion__title'>
            <div className='accordion__icon'>
              {activeIndex === index ? (
                <FaRegMinusSquare />
              ) : (
                <FaRegPlusSquare />
              )}
            </div>
            <h3
              className={`accordion__title-text ${index === activeIndex ? 'active' : ''}`}>{title}
            </h3>
          </div>
          <SeparatorDots />
          {activeIndex === index && (
            <div className='accordion__content'>
              {children}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

//prettier-ignore
export const About = () => {


  const [activeIndex, setActiveIndex] = useState(0)

  // !VA Pass down the array with the json date for the respective slideshow in the imagedata prop of the SlideshowComponent 
  return (
    <div className='page page-about'>
      <div className="container">
        <article className='about'>
          <section className='section-1'>
            <div className="subtitle">
              <h3 className='rule-above rule-below'>Blues Before Chicago</h3>
            </div>
            <div className="slideshow-container">
              <SlideshowComponent imagedata={chicagoalbumimagedata} />
            </div>
            <p className='text-content'>
            For most people, the word 'blues' conjures up mental imagery of electric guitars, rhythm sections, gritty vocals, and Chicago. </p>
            <p className='text-content'>Chicago blues is more than just a style of music. It also reflects a watershed in American history. Blues didn't originate in Chicago. It migrated there from its origins in the Mississippi Delta. As soon as it hit the big city streets, the character of the music started to change. 
            </p>
            <p className="text-content">
              After the 1920's, blues music was transformed by two seminal events &mdash; a migration and an invention.
            </p>
            <PageBorder />
          </section>


          <section className='section-2'>
            <div className="content">

              <AccordionContent
                className='accordion-title'
                title='Migration'
                index={1}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                >
                <div className="accordion-video">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/7TfgJnXlaxo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p className='text-content'>Starting about a century ago, millions of Black Americans fled the <a href="https://www.britannica.com/event/Jim-Crow-law" target="_blank" rel="noreferrer noopener">Jim Crow-era</a> segregation and persecution of the rural South for greater equality and economic opportunities in the industrialized North. Most of the Mississippi blues musicians followed the <a href="https://www.britannica.com/event/Great-Migration" target="_blank" rel="noreferrer noopener">Great Migration</a> northward to Chicago and brought their musical roots with them. </p>


              </AccordionContent>

              <AccordionContent
                className='accordion-title'
                title='Transformation '
                index={2}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}>
                <div className="accordion-video">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/k7hjqPm0iBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p className='text-content'>Blues songs started to reflect the urban experience rather than rural Mississippi life. The invention of the electric guitar and amplifiers allowed guitar and harmonica to be heard in crowded, noisy environments such as the <a href="https://maxwellstreetfoundation.org/" target="_blank" rel="noreferrer noopener">Maxwell Street Market</a> and South Side clubs and bars. Amplified guitar and harmonica with a full rhythm section soon became the signature Chicago blues sound.  </p>
              </AccordionContent>

              <AccordionContent
                className='accordion-title acc-3'
                title='Movers'
                index={3}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}>
                <div className="accordion-video">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Gz9ITp1-Fe0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p className='text-content acc-3'>By the early 1940s many blues musicians from Mississippi such as <a href="https://www.biography.com/musician/muddy-waters" target="_blank" rel="noreferrer noopener">Muddy Waters</a>, <a href="https://en.wikipedia.org/wiki/Big_Bill_Broonzy  " target="_blank" rel="noreferrer noopener">Big Bill Broonzy</a>, and <a href="https://en.wikipedia.org/wiki/Howlin%27_Wolf" target="_blank" rel="noreferrer noopener">Howlin' Wolf</a> had migrated to Chicago. That trend continued for younger Mississippi musicians like Elmore James and James Cotton as the electrified Chicago sound became the predominant voice of the blues in popular music. </p>

              </AccordionContent>

              <AccordionContent
                className='accordion-title'
                title='Remainers'
                index={5}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}>
                <div className="accordion-video">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/PrvlpPpnzaI?start=405" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <p className='text-content acc-4'>Two of the most celebrated Delta blues pioneers who did not make the move to Chicago met with tragic early deaths &mdash; <a href="https://www.nps.gov/locations/lowermsdeltaregion/charley-patton.htm" target="_blank" rel="noreferrer noopener">Charley Patton</a> in 1934 and <a href="https://www.udiscovermusic.com/stories/devils-music-myth-robert-johnson/" target="_blank" rel="noreferrer noopener">Robert Johnson</a> in 1938. Most of the others sank into obscurity until the folk music revival of the 1960's, which led to the rediscovery of Mississippi blues icons like <a href="https://www.britannica.com/biography/Mississippi-John-Hurt" target="_blank" rel="noreferrer noopener">John Hurt</a>, <a href="https://www.loudersound.com/features/a-preacher-a-killer-son-house-king-of-the-delta-blues" target="_blank" rel="noreferrer noopener">Son House</a> and <a href="https://blues.org/blues_hof_inductee/skip-james/" target="_blank" rel="noreferrer noopener">Skip James</a>.</p>
              </AccordionContent>
            </div>
            <PageBorder />
          </section>

          <section className="section-3">
            <div className="content">

              <div className="slideshow-container">
                <SlideshowComponent imagedata={deltabluesalbumimagedata} />
              </div>


              <p className='text-content'>
                <span className='semibold'>Cross &amp; High</span> is mostly influenced by the Delta blues musicians who lived and played in Mississippi's Yazoo River Basin centered around Clarksdale and Greenville. </p>
                <p className='text-content'>But while Delta blues is probably most known for its Chicago connection, other styles of blues music developed at the same time in other parts of the South. Singers like <a href="https://blues.org/blues_hof_inductee/victoria-spivey/" target="_blank" rel="noreferrer noopener">Victoria Spivey</a>, <a href="https://www.womenshistory.org/education-resources/biographies/gertrude-ma-rainey" target="_blank" rel="noreferrer noopener">Ma Rainey</a> and <a href="https://www.biography.com/musician/bessie-smith" target="_blank" rel="noreferrer noopener">Bessie Smith</a> were already singing blues songs in New Orleans and Memphis by the early 1920s. Around that same time, the guitar started to stand out as the go-to instrument for accompanying blues vocals due to its versatility, portability and affordability.    
              </p>

              <AccordionContent
                className='accordion-title'
                title='Piedmont Blues'
                index={1}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}>
                <figure className='album-img piedmont-blues'>
                  <img src={PiedmontBlues} alt="Piedmont Blues"/>
                </figure>
                <p className='text-content'>In the Southeast, heavily <a href="https://simple.wikipedia.org/wiki/Syncopation" target="_blank" rel="noreferrer noopener">syncopated</a> rhythms derived from <a href="https://www.britannica.com/art/ragtime" target="_blank" rel="noreferrer noopener">ragtime music</a> were pioneered by guitarists like <a href="https://www.allaboutbluesmusic.com/blind-blake/" target="_blank" rel="noreferrer noopener">Blind Blake</a> and <a href="https://en.wikipedia.org/wiki/Blind_Boy_Fuller" target="_blank" rel="noreferrer noopener">Blind Boy Fuller</a> in the 1920's and later by Rev. Gary Davis and Brownie McGhee and Sonny Terry. Piedmont blues is known for an alternating bass fingerpicking style that was popularized during the 1960's folk revival by musicians such as Stefan Grossman, Ry Cooder, Keb' Mo', Taj Mahal and Jorma Kaukonen, and by singer-songwriters like John Prine and Arlo Guthrie.</p>
              </AccordionContent>

              <AccordionContent
                className='accordion-title'
                title='Texas Blues'
                index={3}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}>
                <figure className='album-img texas-blues'>
                  <img src={TexasBlues} alt="Texas Blues"/>
                </figure>
                <p className='text-content'>In the 1920's, <a href="https://en.wikipedia.org/wiki/Blind_Lemon_Jefferson" target="_blank" rel="noreferrer noopener">Blind Lemon Jefferson's</a> innovative guitar and <a href="https://www.britannica.com/biography/Blind-Willie-Johnson" target="_blank" rel="noreferrer noopener">Blind Willie Johnson's</a> slide guitar fusing instrumental virtuosity and gospel blues influenced later Texas Blues musicians such as Lightnin' Hopkins and T-Bone Walker. Blind Lemon Jefferson's <a href="https://en.wikipedia.org/wiki/See_That_My_Grave_Is_Kept_Clean" target="_blank" rel="noreferrer noopener"><span className='text-light-emphasis'>See That My Grave Is Kept Clean</span></a> has been covered by the Grateful Dead, Bob Dylan, Canned Heat, Peter Paul &amp; Mary and Phish, among many others. </p>

              </AccordionContent>

              <AccordionContent
                className='accordion-title'
                title='Country Blues'
                index={2}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}>
                <figure className='album-img texas-blues'>
                  <img src={CountryBlues} alt="Country Blues"/>
                </figure>
                <p className='text-content'>Country Blues refers to acoustic blues guitar music that originated in the American South during the early 20th century. It often refers to blues music that doesn't neatly fit into the Delta or Piedmont category, such as <a href="https://www.biography.com/musician/lead-belly" target="_blank" rel="noreferrer noopener">Lead Belly</a> and <a href="https://en.wikipedia.org/wiki/Tampa_Red" target="_blank" rel="noreferrer noopener">Tampa Red</a>. The term became widely used during the 1960's folk revival as a counterpoint to 'urban blues', i.e. music played on electric guitars through amplifiers. </p>

              </AccordionContent>
              
            </div>



            {/* <div className="subtitle grid-item-1">
              <h3 className='rule-above rule-below'>What You'll Hear</h3>
            </div> */}

            <div className='text'>
              <p className='text-content space-below'><span className='semibold'>Cross &amp; High</span> taps into a variety of pre-Chicago blues styles for a diverse sound. Some of the material you may recognize from versions by Eric Clapton, Hot Tuna or the Rolling Stones. Other songs you may have never heard before at all. <span className='semibold'>Cross &amp; High</span> plays a mix of better-known blues songs and obscure country blues material that entertains the audience and provides a historical context to American blues music. </p>
            </div> 
            <PageBorder />
          </section>
        </article>
      </div>
    </div>
  )
}
